import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnChanges {
  @Input() parentForm: FormGroup;
  @Input() id: string;
  @Input() name: string;
  @Input() text: string;
  @Input() isChecked: boolean = true;
  @Input() rightAlign: boolean;

  constructor() { }

  /**
   * ngOnChanges hook
   * detect any change to the checkbox and set the value of the
   * control to it
   * @return {void}
   */
  ngOnChanges() {
    this.parentForm.get(this.name).setValue(this.isChecked);
  }

  /**
   * toggle method
   * sets the value of the checkbox once toggled
   * @param {Event} event HTML element
   * @returns {void}
   */
  public toggle(): void {
    this.isChecked = !this.isChecked;
    this.parentForm.get(this.name).setValue(this.isChecked);
  }
}
